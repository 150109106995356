<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col cols="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Avec > Labels par activités" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear
          indeterminate
          :active="loading || running"
        ></v-progress-linear>

        <v-card flat outlined class="mx-auto">
          <v-card-title class="fonct-weight-regular">
            <v-row no-gutters>
              <!-- Titre de la table -->
              <div>
                Consulter les labels par activités
              </div>

              <v-spacer></v-spacer>

              <!-- Bouton de mode modification -->
              <v-btn 
              v-if="canEditRole && !modeEdition"
              icon
              color="primary"
              @click="clickOnModeEdition"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-row
              justify="end"
              no-gutters
              v-if="modeEdition">
                <v-btn
                class="btn"
                color="primary"
                text
                @click="clickOnCancelEdit"
                :disabled="loading"
                > Quitter l'édition
                </v-btn>

                <v-btn 
                icon
                color="primary"
                :disabled="!hasChanged || loading"
                @click="save"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="availableHeaders"
              :items="items"
              item-key="activityName"
              :search="search"
              :custom-filter="filterOnlyCapsText"
              disable-pagination
              hide-default-footer
            >
              <!-- Template d'analyse des items en fonction des colonnes pour modifier l'affichage du contenu des cellules -->
              <template v-for="head in availableHeaders" v-slot:[`item.${head.value}`]="{ item }">
                <div :key="head.value">

                  <!-- Colonne "activité" on affiche le nom de l'activité -->
                  <div v-if="head.value == 'activityName'">
                    {{ item[head.value] }}
                  </div>

                  <div v-else>
                    <v-row 
                    no-gutters
                    >
                      <div v-if="!modeEdition">
                        {{ item[head.value].value }}
                      </div>

                      <v-text-field 
                      v-if="modeEdition"
                      placeholder="La spécialisation"
                      dense
                      v-model="item[head.value].value"
                      clearable
                      @change="changeIndicator(item, head)"                        
                      @click:clear="clearIndicator(item, head)">
                      </v-text-field>
                    </v-row>
                  </div>
                </div>
              </template>

              <template v-slot:top>
                <v-row class="mb-4">                  
                  <v-text-field
                    v-model="search"
                    label="Rechercher une activité"
                    class="mx-4"
                  ></v-text-field>
                </v-row>
              </template>              
            </v-data-table>            
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions
          v-if="modeEdition">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="ma-2 px-4 btn"
              color="primary"
              :disabled="!hasChanged || loading"
              @click="save"
              >
              <div class="capitalize">
                enregistrer
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>        
      </v-col> 
    </v-row>   

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>


<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import { ActivityService } from "@/service/sfr/activity_service.js";
import { ConfAppAvecIndicatorsService } from "@/service/conf/app_avec_indicators_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import * as logger from "@/tools/logger.js";



export default {
  name: "ConfAppIndicators",
  components: {
    Workflow,
    TitleAndReturnComponent,
    AlertNotSavedModifsComponent,
  },
  mixins: [
    WorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],

  data() {
    return {

      /** les services métier */
      service: null,
      serviceActivity: null,

      /** variable pour l'affichage de chargement de données */
      loading: false,
      running: false,

      /** Indique si l'utilisateur peut modifier les rôles */
      canEditRole: true,
      /** Indique que l'utilisateur est en mode d'édition des rôles */
      modeEdition: false,

      /** Tableau des headers de la vue table */
      headers: [],

      /** les labels */
      entities: [],

      /** les activités */
      activities: [],
      activitiesSource: [],

      /** Les lignes de la table */
      items: [],
      /** les items source de la table */
      itemsSource: [],

      // variable pour le champ de recherche
      search: null,
    };
  },

  methods: {

    /** Méthode de chargement des datas */
    async load() {
      try {
        logger.debug("Chargement des données de la vue.");
        this.loading = true;

        // Récupération de l'ensemble des activité
        this.activities = await this.serviceActivity.getActivities();

        // Récupération de l'ensemble des labels
        this.entities = await this.service.getAll();

        // Construction de la liste des activités (juste le nom)
        this.activitiesSource = JSON.parse(JSON.stringify(this.activities));
        
        // Construction des rows de la table
        this.items = this.buildItems();
        this.itemsSource = JSON.parse(JSON.stringify(this.items));

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Sauvegarde des données */
    async save() {
      try {
        this.loading = true;

        let indicatorHasChanged = this.findIndicatorHasChanged();

        if (indicatorHasChanged.length > 0) {

          // Parcours la liste de spécialisations qui ont changées
          for (let indicator of indicatorHasChanged) {

            let indi = [];

            // Parcours es rows affichée
            for (let item of this.items) {
              if (!item[indicator].isdefault) {
                indi.push({
                  activityId: item.id,
                  label: item[indicator].value,
                });
              }
            }

            // Récupération de l'entité source
            let indicatorByActivity = this.entities.find((e) => e.key == indicator);
            // Association du tableau des rows
            indicatorByActivity.labelsByActivity  = indi;

            await this.service.update(indicatorByActivity);
          }
          this.modeEdition = false;
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "sauvegarde des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },


    /** Construit les lignes de la table */
    buildItems() {
      let items = [];

      // Parcours les activités pour la correspondance
      for (let activity of this.activities) {
        let item = {};

        item.id = activity.id;
        item.activityName = activity.digitalName;

        for (let key of this.entities) {

          // Objet d'info pour une spécialisation de label
          item[key.key] = {};
          item[key.key].isdefault = true;
          item[key.key].value = null;

          // Récupère la spécialisation si existante
          let found = key.labelsByActivity.find((e) => e.activityId == activity.id);

          if (found) {
            item[key.key].isdefault = false;
            item[key.key].value = found.label;  
          } else {
            item[key.key].isdefault = true;
            item[key.key].value = "";  
            // item[key.key].value = key.defaultLabel;  
          }
        }
        
        items.push(item);
      }

      // Tri des entité par ordre alpha
      items.sort(function(a, b) {
        return a.activityName.localeCompare(b.activityName);
      })

      return items
    },

    /** Trouve tous les labels qui ont changés */
    findIndicatorHasChanged() {
      let indicatorChanged = [];

      // Parcours l'ensemble des activités
      for (let item of this.items) {

        // Récupération de l'item source identique
        let itemSrc = this.itemsSource.find((i) => i.id == item.id);

        // Parcours de chaque clé
        for (let label of this.entities) {
          if (item[label.key].value != itemSrc[label.key].value) {           
            if (!indicatorChanged.includes(label.key)) {
              indicatorChanged.push(label.key);
            }
          }
        }
      }

      return indicatorChanged;
    },

    /** Evènement sur la modification de la spécialisation.
     * Si le texte est vide, remet le statut par défaut
     */
    changeIndicator(row, col) {
      // Texte vide --> on remet par défaut
      if (row[col.value].value == "") {
        row[col.value].isdefault = true;
      } else {
        row[col.value].isdefault = false;
      }
    },

    /** Evènement sur la suppression du contenu d'une cellule */
    clearIndicator(row, col) {
      // Passe la cellule en valeur par défaut et la vide.
      row[col.value].isdefault = true;
      row[col.value].value = "";
    },

    /** Evènement d'activation du mode édition */
    clickOnModeEdition() {
      this.modeEdition = true;
    },

    /** Annule le mode édition et remet les modifications dans l'état initial */
    clickOnCancelEdit() {
      if(this.hasChanged) {
        this.showAlertQuit = true;
        this.nextAlertQuit = function(value) {
          // la valeur est à undefined quand on veut quitter sans enregistrer
          // la valeur est à false qun on veut annuler la sortie
          if (value == undefined) {
            this.modeEdition = false;
            this.items = JSON.parse(JSON.stringify(this.itemsSource));
          }
        };
      } else {
        this.modeEdition = false;
      }
    },

    /** Méthode de recherche d'un rôle */
    filterOnlyCapsText (value, search) {

      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
    },


    // Initialisation des étapes du workflow en fonction des droits de l'utilisateur
    initSteps() {
      this.addStepForWorkflow("Choisir une application");
      this.addStepForWorkflow("Modifier la configuration");
      this.nextStepForWorkflow();
    },
  },

  computed: {

    availableHeaders() {
      let headers = [];

      // Ajout du header d'activité
      headers.push({text: "Activité", value: "activityName", width: "200px", });
      for (let key of this.entities) {
        let col = {};
        col.text = `${key.key} (défaut : ${key.defaultLabel})`;
        col.value = key.key;
        col.align = 'left';
        col.sortable = false;

        headers.push(col);
      }

      return headers;
    },


    /** retourne vrai si un des items à changé par rapport à sa source */
    hasChanged() {
      let changed = false;
      
      /** Le tableau source est à null --> le modèle ne peut avoir changé */
      if (!this.activitiesSource) return false;

      let find = this.findIndicatorHasChanged();
      if (find.length > 0) {
        changed = true;
      }

      return changed;
    },

  },

  mounted() {
    // Initialisation du worflow
    this.initSteps();

    //on affiche le bouton retour
    this.showBackButton = true;

    /** Instanciation des services */
    this.service = new ConfAppAvecIndicatorsService(this.$api.getAppAvecIndicatorsApi());
    this.serviceActivity = new ActivityService(this.$api);

    this.load();
  },  
}
</script>